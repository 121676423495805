import { JSON_SCHEMAS } from "@components/utils/constants";
import AttractionSchemaFacade from "@libs/schema/facades/AttractionSchemaFacade";
import FAQSchemaFacade from "@libs/schema/facades/FAQSchemaFacade";
import HotelSchemaFacade from "@libs/schema/facades/HotelSchemaFacade";
import LocalBusinessSchemaFacade from "@libs/schema/facades/LocalBusinessSchemaFacade";
import PersonSchemaFacade from "@libs/schema/facades/PersonSchemaFacade";
import TripSchemaFacade from "@libs/schema/facades/TripSchemaFacade";
import { IJsonLdSchema } from "../JsonLdSchema";
import BreadcrumbSchemaFacade from "../facades/BreadcrumbSchemaFacade";

class JsonLdSchemaFactory {
  data: IJsonLdSchema["data"];
  type: IJsonLdSchema["type"];

  constructor({ data, type }: IJsonLdSchema) {
    this.data = data;
    this.type = type;
  }

  create(): string {
    const { breadcrumbObjects, schema, content } = this.data;
    switch (this.type) {
      case JSON_SCHEMAS.BREADCRUMB:
        return BreadcrumbSchemaFacade.createBreadcrumbSchema({
          breadcrumbObjects,
        });
      case JSON_SCHEMAS.LOCAL_BUSINESS:
        return LocalBusinessSchemaFacade.createLocalBusinessSchema();
      case JSON_SCHEMAS.FAQ:
        if (schema !== undefined) {
          return FAQSchemaFacade.createFAQSchema(schema);
        } else {
          return "";
        }
      case JSON_SCHEMAS.ATTRACTION:
        return AttractionSchemaFacade.createAttractionSchema(content);
      case JSON_SCHEMAS.HOTEL:
        return HotelSchemaFacade.createHotelSchema(content);
      case JSON_SCHEMAS.TRIP:
        return TripSchemaFacade.createTripSchema(content);
      case JSON_SCHEMAS.PERSON:
        return PersonSchemaFacade.createPersonSchema(content);
    }
  }
}

export default JsonLdSchemaFactory;
