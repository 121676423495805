import getConfig from "next/config";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class HotelSchemaFacade {
  static createHotelSchema(content: any): string {
    const { publicRuntimeConfig } = getConfig();
    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": content.Property_Category,
      name: content.Title,
      image: content.Media.PrimaryImage[0].url,
      url: `${publicRuntimeConfig.WEB_URL}/${content.SEO.Slug}`,
      priceRange: `$${content.Rate}`,
      address: {
        "@type": "PostalAddress",
        addressCountry: "CR",
      },
    });
  }
}

export default HotelSchemaFacade;
