class FAQSchemaFacade {
  static createFAQSchema(schema: string) {
    if (schema !== undefined && schema.length > 0) {
      return schema;
    } else {
      return "";
    }
  }
}

export default FAQSchemaFacade;
