import Image from "next/image";

interface CloudinaryImageProps {
  /** The Cloudinary image URL */
  src: string;
  /** Alternative text for accessibility */
  alt: string;
  /** Optional additional CSS classes */
  className?: string;
  /** Default width of the image (for larger screens) */
  width?: number;
  /** Default height of the image (for larger screens) */
  height?: number;
  /** Array of breakpoints for responsive behavior */
  breakpoints?: number[];
}

const CloudinaryImageV2: React.FC<CloudinaryImageProps> = ({
  src,
  alt,
  className = "",
  width = 1440,
  height = 1024,
  breakpoints = [390, 576, 768, 992],
}) => {
  // Calculate aspect ratio to maintain image proportions
  const aspectRatio = height / width;

  // Use the largest breakpoint for the default image width
  const defaultWidth = Math.max(...breakpoints);

  // Generate the Cloudinary URL with default width and height
  const optimizedSrc = `${src}?f_auto,q_auto,w_${defaultWidth},h_${Math.round(
    defaultWidth * aspectRatio
  )},c_fill`;

  return (
    <Image
      src={optimizedSrc}
      alt={alt}
      className={`h-full w-full object-cover ${className}`}
      width={width}
      height={height}
      quality={75}
      priority
      placeholder="blur"
      blurDataURL={`${src}?w=10&h=10&f_auto,q_auto`}
      sizes="(max-width: 390px) 100vw,
             (max-width: 576px) 100vw,
             (max-width: 768px) 100vw,
             (max-width: 992px) 100vw,
             100vw"
    />
  );
};

export default CloudinaryImageV2;
