import getConfig from "next/config";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class TripSchemaFacade {
  static createTripSchema(content: any): string {
    const { publicRuntimeConfig } = getConfig();

    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "TouristTrip",
      name: content.Title,
      description: content.Subhead,
      url: `${publicRuntimeConfig.WEB_URL}/packages/${content.SEO.Slug}`,
      provider: {
        "@type": "Organization",
        name: "Costa Rica Vacations",
        url: `${publicRuntimeConfig.WEB_URL}/`,
      },
    });
  }
}

export default TripSchemaFacade;
