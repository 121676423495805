import getConfig from "next/config";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class PersonSchemaFacade {
  static createPersonSchema(content: any): string {
    const { publicRuntimeConfig } = getConfig();

    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Person",
      name: content.Name,
      url: `${publicRuntimeConfig.WEB_URL}${content.url}`,
      image: content.Media.PrimaryImage[0].url,
      jobTitle: content.JobTitle,
      worksFor: {
        "@type": "Organization",
        name: "Vacations Costa Rica",
        url: `${publicRuntimeConfig.WEB_URL}/`,
      },
    });
  }
}

export default PersonSchemaFacade;
