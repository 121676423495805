import { ILocationResponse } from "@customTypes/modelResponses";
import getConfig from "next/config";

interface ILocationActivities {
  "@type": string;
  name?: string;
  description?: string;
  sameAs?: string;
  image?: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class AttractionSchemaFacade {
  static createAttractionSchema(content: any): string {
    const { publicRuntimeConfig } = getConfig();
    let activities: ILocationActivities[] = [];

    content.activities.forEach((activity: ILocationResponse) => {
      let primaryImage = activity.Media?.PrimaryImage;
      activities.push({
        "@type": "TouristAttraction",
        name: activity.Title,
        description: activity.Subhead,
        sameAs: `${publicRuntimeConfig.WEB_URL}/${activity.SEO?.Slug}`,
        image:
          primaryImage !== undefined && primaryImage !== null
            ? primaryImage[0].url
            : "",
      });
    });

    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "TouristDestination",
      name: content.Title,
      description: content.Subhead,
      url: `${publicRuntimeConfig.WEB_URL}/${content.SEO.Slug}`,
      // includesAttraction: { activities },
    });
  }
}

export default AttractionSchemaFacade;
