import { ImageDefault } from "@components/utils/Utils";
import styledParser from "@libs/parser/styled-parser";
import parse from "html-react-parser";
import {
  IImageComponentResponse,
  IMediaComponentResponse,
} from "../types/componentResponses";
import ConditionalWrapper from "./common/ConditionalWrapper";
import ImageCloudinaryV2 from "./skeleton/ImageCloudinaryV2";
interface IHeroFullWidth {
  image: string | IMediaComponentResponse | undefined | IImageComponentResponse;
  title: string;
  subtitle?: string;
  eyebrow?: string | null;
  isEyebrowRichText?: boolean;
}

const HeroFullWidth = ({
  image,
  title,
  subtitle,
  eyebrow,
  isEyebrowRichText,
}: IHeroFullWidth): JSX.Element => {
  return (
    <div className="relative h-[calc(100vh-72px)] max-h-[684px] w-[100vw] desktop:h-[calc(100vh-58px)] desktop:max-h-[100%]">
      <div
        className="absolute z-[1] h-full w-full"
        style={{
          background:
            "linear-gradient(90deg, #000000 -14.21%, rgba(0, 0, 0, 0) 58.05%)",
        }}
      />
      <ImageCloudinaryV2
        src={ImageDefault(image)}
        alt="w"
        className="h-[calc(100vh-72px)] w-full desktop:h-[calc(100vh-58px)]"
      />

      <ConditionalWrapper
        condition={true}
        wrapper={(children) => {
          return (
            <div className="absolute bottom-[160px] z-[2] w-full desktop:bottom-[240px]">
              {children}
            </div>
          );
        }}
      >
        <div className="mx-auto w-full px-[16px] text-white desktop:max-w-[1024px]">
          <div className="flex flex-col gap-[32px] desktop:max-w-[466px]">
            <h1 className={`text-herotitle font-bold text-white`}>
              {parse(title)}
            </h1>
            {subtitle && (
              <div className="text-subtitle text-[#F8FAFC]">{subtitle}</div>
            )}
            {eyebrow && (
              <div className="text-p text-white">
                {isEyebrowRichText
                  ? styledParser(eyebrow, {
                      skipParagraphStyling: true,
                      isHeroEyebrow: true,
                    })
                  : eyebrow}
              </div>
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export default HeroFullWidth;
