import { HOME_PAGE_DETAILED, TAG_PACKAGE_THEME } from "@components/home/routes";
import MainWrapper from "@components/pages/common/MainWrapper";
import { JSON_SCHEMAS } from "@components/utils/constants";
import { IHomePageResponse } from "@customTypes/commonResponses";
import { IPackageThemeResponse } from "@customTypes/modelResponses";
import withLazyLoad from "@hooks/withLazyLoad";
import JsonLdSchema from "@libs/schema/JsonLdSchema";
import HomeHero from "@modules/homePage/HomeHero";
import SectionsContainer from "@modules/homePage/SectionsContainer";
import fetchGet from "@services/api/fetchGet";
import baseApiCalls from "@services/baseApiCalls";
import { GetStaticProps } from "next";
import dynamic from "next/dynamic";

// Dynamic imports start here
const HomePackages = dynamic(
  () => import("@modules/homePage/sections/HomePackagesV2"),
  {
    ssr: false,
  }
);

const UniqueSellingPoints = dynamic(
  () => import("@components/UniqueSellingPoints"),
  {
    ssr: false,
  }
);

const HomePackageThemes = dynamic(
  () => import("@modules/homePage/sections/HomePackageThemes"),
  {
    ssr: false,
  }
);

const SearchMgtHome = dynamic(
  () => import("@components/SearchManagement/SearchMgtHome"),
  {
    ssr: false,
  }
);

const HomeExperts = dynamic(
  () => import("@modules/homePage/sections/HomeExpertsV2"),
  {
    ssr: false,
  }
);

const HomeReviews = dynamic(
  () => import("@modules/homePage/sections/HomeReviewsV2"),
  {
    ssr: false,
  }
);

const HomeDestinations = dynamic(
  () => import("@modules/homePage/sections/HomeDestinationsV2"),
  {
    ssr: false,
  }
);

const HomeAboutUs = dynamic(
  () => import("@modules/homePage/sections/HomeAboutUs"),
  {
    ssr: false,
  }
);

const HomeAccommodations = dynamic(
  () => import("@modules/homePage/sections/HomeAccommodationsV2"),
  {
    ssr: false,
  }
);

interface IHomePage {
  homePage: IHomePageResponse;
  packageTheme: IPackageThemeResponse[];
}

const LazyComponent = ({ homePage, packageTheme }: IHomePage) => {
  return (
    <>
      <UniqueSellingPoints usp={homePage.Unique_Selling_Point} />
      <SectionsContainer className="desktop:mt-[1.5rem]">
        <HomePackageThemes
          title={homePage.PackageThemes_Section_Headline}
          tags={packageTheme}
        />
      </SectionsContainer>
      <SearchMgtHome />
      <SectionsContainer>
        <HomeExperts
          title={homePage.TCs_Section_Headline}
          text={homePage.TCs_Section_Text}
          experts={homePage.travel_consultants}
        />
        <HomeReviews
          title={homePage.Reviews_Section_Headline}
          section={homePage.Reviews_Section_Text}
          reviews={homePage.reviews}
          //horizontalScroll={homePage.Reviews_Horizontal_Scroll}
        />
        <HomeDestinations
          locations={homePage.locations}
          title={homePage.Destinations_Section_Headline}
          body={homePage.Destinations_Section_Text}
        />
      </SectionsContainer>
      <HomeAboutUs
        key={homePage.About_Us.id}
        title={homePage.About_Us.AboutUsHeadline}
        text={homePage.About_Us.AboutUsText}
        image={
          homePage?.About_Us?.AboutUsMedia && homePage.About_Us.AboutUsMedia[0]
        }
      />
      <SectionsContainer noMarginTopDesktop>
        <HomeAccommodations
          accommodations={homePage.accommodations}
          title={homePage.Accommodations_Section_Headline}
          body={homePage.Accommodations_Section_Text}
        />
      </SectionsContainer>
    </>
  );
};

const LazyComponentWithLazyLoad = withLazyLoad(LazyComponent);

export default function Home({
  homePage,
  packageTheme,
}: IHomePage): JSX.Element {
  return (
    <>
      <JsonLdSchema
        data={{ breadcrumbObjects: undefined }}
        type={JSON_SCHEMAS.LOCAL_BUSINESS}
      />
      <MainWrapper>
        <HomeHero
          title={homePage.Slogan}
          subtitle={homePage.Subtitle || ""}
          eyebrow={homePage.Eyebrow}
          image={homePage.Hero_Media?.[0]}
        />
        <SectionsContainer>
          <HomePackages
            title={homePage.Packages_Section_Headline}
            body={homePage.Packages_Section_Text}
            packages={homePage.packages}
          />
        </SectionsContainer>
        <LazyComponentWithLazyLoad
          homePage={homePage}
          packageTheme={packageTheme}
        />
      </MainWrapper>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  /*
  const homePageData = await fetchGet(HOME_PAGE_DETAILED);
  const homePage = await homePageData.json();

  const packageThemeData = await fetchGet(TAG_PACKAGE_THEME);
  const packageTheme = await packageThemeData.json();
  */
  const [homePage, packageTheme] = await Promise.all([
    fetchGet(HOME_PAGE_DETAILED).then((res) => res.json()),
    fetchGet(TAG_PACKAGE_THEME).then((res) => res.json()),
  ]);

  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls();

  const pageType = 1;

  return {
    props: {
      footer,
      topNavigation,
      mediaAssets,
      homePage,
      packageTheme,
      pageType,
      menus,
      usp,
      searchManagement: searchManagementRes,
    },
    revalidate: Number(process.env.REVALIDATE_TIMER),
  };
};
