import { useMemo } from "react";

import { JSON_SCHEMAS, JsonLdSchemas } from "@components/utils/constants";
import { IbreadcrumbObject } from "@modules/breadcrumb/utils/breadcrumbHelpers";
import parse from "html-react-parser";
import Head from "next/head";
import JsonLdSchemaFactory from "./factories/JsonLdSchemaFactory";

export interface IJsonLdSchema {
  data: {
    breadcrumbObjects?: IbreadcrumbObject[];
    schema?: string;
    content?: any;
  };
  type: JsonLdSchemas;
}

const JsonLdSchema = ({ data, type }: IJsonLdSchema): JSX.Element => {
  const schema = useMemo(() => {
    const schemaFactory = new JsonLdSchemaFactory({ data, type });

    switch (type) {
      case JSON_SCHEMAS.BREADCRUMB:
        const breadcrumbSchema = schemaFactory.create();

        return (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: breadcrumbSchema,
            }}
          />
        );
      case JSON_SCHEMAS.LOCAL_BUSINESS:
        const localbusinessSchema = schemaFactory.create();

        return (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: localbusinessSchema,
            }}
          />
        );
      case JSON_SCHEMAS.FAQ:
        const faqSchema = schemaFactory.create();

        return parse(faqSchema);
      case JSON_SCHEMAS.HOTEL:
        const hotelSchema = schemaFactory.create();
        if (data.content.Property_Category === "Hotel") {
          return (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: hotelSchema,
              }}
            />
          );
        } else {
          return "";
        }

      case JSON_SCHEMAS.ATTRACTION:
      case JSON_SCHEMAS.TRIP:
      case JSON_SCHEMAS.PERSON:
        const schema = schemaFactory.create();
        return (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: schema,
            }}
          />
        );

      default:
        throw new Error(`Invalid type: ${type}`);
    }
  }, [data, type]);

  return <Head>{schema}</Head>;
};

export default JsonLdSchema;
