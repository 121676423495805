// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class LocalBusinessSchemaFacade {
  static createLocalBusinessSchema(): string {
    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      name: "vacations costa rica",
      image: "",
      "@id": "",
      url: "https://www.vacationscostarica.com/",
      telephone: "1-800-606-1860",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Calle 70",
        addressLocality: "San Jose",
        postalCode: "10109",
        addressCountry: "CR",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: 9.934739,
        longitude: -84.087502,
      },
      sameAs: [
        "https://www.facebook.com/costaricanvacations ",
        "https://twitter.com/CRVTravel ",
        "https://www.instagram.com/costaricanvacations/",
      ],
    });
  }
}

export default LocalBusinessSchemaFacade;
