import HeroFullWidth from "@components/HeroFullWidth";
import OpenGraphImage from "@components/OpenGraphImage";
import { IMediaComponentResponse } from "@customTypes/componentResponses";

interface IHomeTitle {
  title: string;
  subtitle?: string;
  eyebrow?: string | null;
  image?: IMediaComponentResponse;
}

const HomeHero = ({
  title,
  subtitle,
  eyebrow,
  image,
}: IHomeTitle): JSX.Element => {
  return (
    <>
      <OpenGraphImage image={image} alt={title}></OpenGraphImage>
      <div className="desktop:mt-[58px]">
        <HeroFullWidth
          title={title}
          subtitle={subtitle}
          eyebrow={eyebrow}
          image={image}
          isEyebrowRichText={true}
        />
      </div>
    </>
  );
};
export default HomeHero;

/*
const HomeTitle = ({ title, eyebrow, image }: IHomeTitle): JSX.Element => {
  return (
      <>
        <OpenGraphImage image={image} alt={title} ></OpenGraphImage>
        <SectionsContainer className="desktop:mt-[4.625rem] desktop:w-[86rem] desktop:max-w-[100%]">
          <div className="relative mx-[0.6rem] desktop:mt-[4.5rem] desktop:-mb-2 desktopLarge:m-0">

              <Hero
                title={title}
                eyebrow={eyebrow}
                image={image}
                isEyebrowRichText={true}
              />

          </div>
        </SectionsContainer>
      </>
  );
};
export default HomeTitle;
*/
