interface ISectionsContainer {
  children: JSX.Element | JSX.Element[];
  noMarginTopDesktop?: boolean;
  className?: string;
}

const SectionsContainer = ({
  children,
  noMarginTopDesktop = false,
  className = "",
}: ISectionsContainer): JSX.Element => {
  return (
    <div
      className={`mx-auto mt-[1rem] desktop:w-[82rem] desktop:max-w-[100%] ${className}`}
    >
      {children}
    </div>
  );
};

export default SectionsContainer;
