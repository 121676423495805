import getConfig from "next/config";
import { IJsonLdSchema } from "../JsonLdSchema";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class BreadcrumbSchemaFacade {
  static createBreadcrumbSchema({
    breadcrumbObjects,
  }: IJsonLdSchema["data"]): string {
    const { publicRuntimeConfig } = getConfig();

    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbObjects
        ? breadcrumbObjects.map((breadcrumbObject, index) => {
            const isLastItem = index === breadcrumbObjects.length - 1;
            return {
              "@type": "ListItem",
              position: ++index,
              name: breadcrumbObject.label,
              item: isLastItem
                ? undefined
                : `${publicRuntimeConfig.WEB_URL}${breadcrumbObject.link}`,
            };
          })
        : [],
    });
  }
}

export default BreadcrumbSchemaFacade;
